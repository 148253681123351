import * as React from 'react';
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function HowToModal(props) {
    const [open, setOpen] = React.useState(true);
    const handleClose = () => setOpen(false);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Welcome to Skate Alpha!
                </Typography>
                <Typography id="modal-modal-description" sx={{mt: 2}}>
                    You have 3 credits to spend. Once you have decided which board(s) you want, click the cart icon to make your selection.

                    You may <strong>not</strong> buy your own board or duplicates of another board!
                </Typography>
            </Box>
        </Modal>
    )
}
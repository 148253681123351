import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {CardActionArea} from "@mui/material";


export default function MediaCard(props) {
    const {product_name, price, product_image, product_url, setProductUrl} = props;
    const displayPrice = price === 1 ? price + " credit" : price + " credits"
    return (
        <div style={{display: "flex", justifyContent: "center"}}>
            <Card sx={{minWidth: 300, maxWidth: 300}}>
                <CardActionArea onClick={() => setProductUrl(product_url)}>
                    <CardMedia style={{display: "flex", justifyContent: "center"}}>
                        <img src={product_image} alt={product_name} style={{maxHeight: 300}}/>
                    </CardMedia>

                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            {product_name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {displayPrice}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
         </div>
    );
}
export const products =
[
  {
    "student_name": "Hendrix Speer",
    "product_name": "Smiley Skateboards",
    "product_image": "https://storage.googleapis.com/skateboard_pictures/hendrix_s.png",
    "product_url": "https://www.canva.com/design/DAFB6ju4bRw/taEKZRhC3dMMslh_kzQ8ZQ/view?website#1",
    "price": 3,
    "Fully Done?": "TRUE",
    "Checked on 6/2?": "TRUE",
    "Name": "Smiley Skateboards - 3 credit(s)",
    "Purchases": 5,
    "Offset": "",
    "Total": 5,
    "Revenue": 15,
    "": "",
    "__1": "",
    "Responses": "",
    "Threshold": ""
  },
  {
    "student_name": "Madison Marchand",
    "product_name": "Wild Skateboards",
    "product_image": "https://storage.googleapis.com/skateboard_pictures/madison.png",
    "product_url": "https://www.canva.com/design/DAFB6tGHKz0/BuNhw8-QxlQmyUKG2rpUgg/view?website#1",
    "price": 3,
    "Fully Done?": "TRUE",
    "Checked on 6/2?": "TRUE",
    "Name": "Wild Skateboards - 3 credit(s)",
    "Purchases": 5,
    "Offset": -1,
    "Total": 4,
    "Revenue": 12,
    "": "",
    "__1": "",
    "Responses": "",
    "Threshold": ""
  },
  {
    "student_name": "Lois Song",
    "product_name": "Garden Skateboards - Mushroom Deck",
    "product_image": "https://storage.googleapis.com/skateboard_pictures/lois.png",
    "product_url": "https://www.canva.com/design/DAFBQoE2SkY/vWovmMqIzMLoRVve8rKQoA/view?website#1",
    "price": 2,
    "Fully Done?": "TRUE",
    "Checked on 6/2?": "TRUE",
    "Name": "Garden Skateboards - Mushroom Deck - 2 credit(s)",
    "Purchases": 5,
    "Offset": "",
    "Total": 5,
    "Revenue": 10,
    "": "",
    "__1": "",
    "Responses": "",
    "Threshold": ""
  },
  {
    "student_name": "Riley King",
    "product_name": "Soggy Boards - Sunset Deck",
    "product_image": "https://storage.googleapis.com/skateboard_pictures/riley.png",
    "product_url": "https://www.canva.com/design/DAFB0Arumwk/7zQuTlKewEaAU6JFFIhZHw/view?website#1",
    "price": 3,
    "Fully Done?": "TRUE",
    "Checked on 6/2?": "TRUE",
    "Name": "Soggy Boards - Sunset Deck - 3 credit(s)",
    "Purchases": 3,
    "Offset": "",
    "Total": 3,
    "Revenue": 9,
    "": "",
    "__1": "",
    "Responses": "",
    "Threshold": ""
  },
  {
    "student_name": "Bella Royston",
    "product_name": "Mind Your Business",
    "product_image": "https://storage.googleapis.com/skateboard_pictures/Screen-Shot-2022-05-26-at-9-13-11-AM.png",
    "product_url": "https://www.canva.com/design/DAFBijJFgzA/jztmqCvlwcPPc72FHKcaWg/view?website#1",
    "price": 2,
    "Fully Done?": "FALSE",
    "Checked on 6/2?": "TRUE",
    "Name": "Mind Your Business - 2 credit(s)",
    "Purchases": 5,
    "Offset": -1,
    "Total": 4,
    "Revenue": 8,
    "": "",
    "__1": "",
    "Responses": 42,
    "Threshold": 4
  },
  {
    "student_name": "Jack Kull",
    "product_name": "",
    "product_image": "",
    "product_url": "https://www.canva.com/design/DAFB0KYglRU/s-JAS7eo5htsdMuSfTUOUg/view?website#1",
    "price": 1,
    "Fully Done?": "FALSE",
    "Checked on 6/2?": "TRUE",
    "Name": "- 1 credit(s)",
    "Purchases": 2,
    "Offset": "",
    "Total": 2,
    "Revenue": 2,
    "": "",
    "__1": "",
    "Responses": "",
    "Threshold": ""
  },
  {
    "student_name": "Luke McClelland",
    "product_name": "Space Decks Board C-7D",
    "product_image": "https://storage.googleapis.com/skateboard_pictures/Luke.jpeg",
    "product_url": "https://www.canva.com/design/DAFBQ-mnwkY/fJd9NQ6czmV67GyJLHv6FA/view?website#1",
    "price": 2,
    "Fully Done?": "TRUE",
    "Checked on 6/2?": "TRUE",
    "Name": "Space Decks Board C-7D - 2 credit(s)",
    "Purchases": 4,
    "Offset": "",
    "Total": 4,
    "Revenue": 8,
    "": "",
    "__1": "",
    "Responses": "",
    "Threshold": ""
  },
  {
    "student_name": "Rylan Smerklo",
    "product_name": "Essentials Board Co.",
    "product_image": "https://storage.googleapis.com/skateboard_pictures/rylan.png",
    "product_url": "https://www.canva.com/design/DAFB0Axm4-8/6lDihi4lhHlgYJlZ_IvbIA/view?website#1",
    "price": 2,
    "Fully Done?": "TRUE",
    "Checked on 6/2?": "TRUE",
    "Name": "Essentials Board Co. - 2 credit(s)",
    "Purchases": 4,
    "Offset": "",
    "Total": 4,
    "Revenue": 8,
    "": "",
    "__1": "",
    "Responses": "",
    "Threshold": ""
  },
  {
    "student_name": "Christopher McGlathery",
    "product_name": "Zoom Skateboard",
    "product_image": "https://storage.googleapis.com/skateboard_pictures/christopher.png",
    "product_url": "https://www.canva.com/design/DAFCdtLm1FY/3UU-WgR7AuoFeNkKXunvEQ/view?website#1",
    "price": 1,
    "Fully Done?": "TRUE",
    "Checked on 6/2?": "TRUE",
    "Name": "Zoom Skateboard - 1 credit(s)",
    "Purchases": 8,
    "Offset": "",
    "Total": 8,
    "Revenue": 8,
    "": "",
    "__1": "",
    "Responses": "",
    "Threshold": ""
  },
  {
    "student_name": "Wyatt Jayasuriya",
    "product_name": "Monke Madness",
    "product_image": "https://storage.googleapis.com/skateboard_pictures/Wyatt.png",
    "product_url": "https://www.canva.com/design/DAFB0JCuhEU/-pDXGR4rJEIoZT5ns5sUHg/view?website#1",
    "price": 1,
    "Fully Done?": "TRUE",
    "Checked on 6/2?": "TRUE",
    "Name": "Monke Madness - 1 credit(s)",
    "Purchases": 7,
    "Offset": "",
    "Total": 7,
    "Revenue": 7,
    "": "",
    "__1": "",
    "Responses": "",
    "Threshold": ""
  },
  {
    "student_name": "Ezra Lenfest",
    "product_name": "Stronk Skateboards",
    "product_image": "https://storage.googleapis.com/skateboard_pictures/ezra.png",
    "product_url": "https://www.canva.com/design/DAFB0O4mZ0U/cW1uhjKct6QB-kaVGIAtNA/view?website#1",
    "price": 1,
    "Fully Done?": "FALSE",
    "Checked on 6/2?": "TRUE",
    "Name": "Stronk Skateboards - 1 credit(s)",
    "Purchases": 6,
    "Offset": "",
    "Total": 6,
    "Revenue": 6,
    "": "",
    "__1": "",
    "Responses": "",
    "Threshold": ""
  },
  {
    "student_name": "Ama Isackson",
    "product_name": "Mood Skateboards",
    "product_image": "https://storage.googleapis.com/skateboard_pictures/Screen%20Shot%202022-06-03%20at%209.02.55%20AM.png",
    "product_url": "https://www.canva.com/design/DAFB6vQGHVs/at2TkFEzoDhwHZhUW-0b2g/view?website#1",
    "price": 3,
    "Fully Done?": "TRUE",
    "Checked on 6/2?": "TRUE",
    "Name": "",
    "Purchases": 2,
    "Offset": "",
    "Total": 2,
    "Revenue": 6,
    "": "",
    "__1": "",
    "Responses": "",
    "Threshold": ""
  },
  {
    "student_name": "Charley Schofman",
    "product_name": "Salty's Skateboards - Heart Deck",
    "product_image": "https://storage.googleapis.com/skateboard_pictures/charley.png",
    "product_url": "https://www.canva.com/design/DAFBitaga1k/DJc7L-yuLBXP28QDmdyr8g/view?website#1",
    "price": 1,
    "Fully Done?": "TRUE",
    "Checked on 6/2?": "TRUE",
    "Name": "Salty's Skateboards - Heart Deck - 1 credit(s)",
    "Purchases": 5,
    "Offset": "",
    "Total": 5,
    "Revenue": 5,
    "": "",
    "__1": "",
    "Responses": "",
    "Threshold": ""
  },
  {
    "student_name": "Jaiden Suthar",
    "product_name": "GeForce It",
    "product_image": "https://storage.googleapis.com/skateboard_pictures/jaiden.png",
    "product_url": "https://www.canva.com/design/DAFB0CnqNlM/9PPgZ7J9NA0kFnH1TGsmng/view?website#4",
    "price": 2,
    "Fully Done?": "TRUE",
    "Checked on 6/2?": "TRUE",
    "Name": "GeForce It - 2 credit(s)",
    "Purchases": 3,
    "Offset": -1,
    "Total": 2,
    "Revenue": 4,
    "": "",
    "__1": "",
    "Responses": "",
    "Threshold": ""
  },
  {
    "student_name": "Hendrix Haselwood",
    "product_name": "Ska Decks - New Orleans Deck",
    "product_image": "https://storage.googleapis.com/skateboard_pictures/hendrix.png",
    "product_url": "https://www.canva.com/design/DAFBKImIyfg/kiiROYqjsrVqln5iSGOGVQ/view?website#1",
    "price": 1,
    "Fully Done?": "FALSE",
    "Checked on 6/2?": "TRUE",
    "Name": "Ska Decks - New Orleans Deck - 1 credit(s)",
    "Purchases": 3,
    "Offset": "",
    "Total": 3,
    "Revenue": 3,
    "": "",
    "__1": "",
    "Responses": "",
    "Threshold": ""
  },
  {
    "student_name": "Archer Stalcup",
    "product_name": "Coach Boards - The Italian Racer",
    "product_image": "https://storage.googleapis.com/skateboard_pictures/archer.png",
    "product_url": "https://www.canva.com/design/DAFB60FwgOo/yKtmBO7ZqHkjsM5KohKMXw/view?website#1",
    "price": 3,
    "Fully Done?": "TRUE",
    "Checked on 6/2?": "TRUE",
    "Name": "Coach Boards - The Italian Racer - 3 credit(s)",
    "Purchases": 2,
    "Offset": -1,
    "Total": 1,
    "Revenue": 3,
    "": "",
    "__1": "",
    "Responses": "",
    "Threshold": ""
  },
  {
    "student_name": "Elle Liemandt",
    "product_name": "",
    "product_image": "",
    "product_url": "",
    "price": "",
    "Fully Done?": "FALSE",
    "Checked on 6/2?": "TRUE",
    "Name": "",
    "Purchases": "",
    "Offset": "",
    "Total": 0,
    "Revenue": 0,
    "": "",
    "__1": "",
    "Responses": "",
    "Threshold": ""
  },
  {
    "student_name": "Halston Haselwood",
    "product_name": "",
    "product_image": "",
    "product_url": "",
    "price": "",
    "Fully Done?": "FALSE",
    "Checked on 6/2?": "TRUE",
    "Name": "",
    "Purchases": "",
    "Offset": "",
    "Total": 0,
    "Revenue": 0,
    "": "",
    "__1": "",
    "Responses": "",
    "Threshold": ""
  },
  {
    "student_name": "Ewan Currie",
    "product_name": "SK8R Pintail Classic",
    "product_image": "https://storage.googleapis.com/skateboard_pictures/ewan.png",
    "product_url": "https://www.canva.com/design/DAFBowSq-s4/QvEN1ZudTpmzZUDzKAbm0g/view?website#1",
    "price": 1,
    "Fully Done?": "TRUE",
    "Checked on 6/2?": "TRUE",
    "Name": "SK8R Pintail Classic - 1 credit(s)",
    "Purchases": 2,
    "Offset": "",
    "Total": 2,
    "Revenue": 2,
    "": "",
    "__1": "",
    "Responses": "",
    "Threshold": ""
  },
  {
    "student_name": "Maddie Price",
    "product_name": "",
    "product_image": "",
    "product_url": "",
    "price": "",
    "Fully Done?": "FALSE",
    "Checked on 6/2?": "FALSE",
    "Name": "",
    "Purchases": "",
    "Offset": "",
    "Total": 0,
    "Revenue": 0,
    "": "",
    "__1": "",
    "Responses": "",
    "Threshold": ""
  },
  {
    "student_name": "Levi Williams",
    "product_name": "",
    "product_image": "",
    "product_url": "",
    "price": "",
    "Fully Done?": "FALSE",
    "Checked on 6/2?": "FALSE",
    "Name": "",
    "Purchases": "",
    "Offset": "",
    "Total": 0,
    "Revenue": 0,
    "": "",
    "__1": "",
    "Responses": "",
    "Threshold": ""
  },
  {
    "student_name": "Cameron Locke",
    "product_name": "",
    "product_image": "",
    "product_url": "",
    "price": "",
    "Fully Done?": "FALSE",
    "Checked on 6/2?": "TRUE",
    "Name": "",
    "Purchases": "",
    "Offset": "",
    "Total": 0,
    "Revenue": 0,
    "": "",
    "__1": "",
    "Responses": "",
    "Threshold": ""
  },
  {
    "student_name": "Rett Mast",
    "product_name": "Circle Skates Pintail",
    "product_image": "https://storage.googleapis.com/skateboard_pictures/Rett.png",
    "product_url": "https://www.canva.com/design/DAFB0Kf2GtA/zn65glHHg1lBXRwoItSKyw/view?website#4",
    "price": 1,
    "Fully Done?": "FALSE",
    "Checked on 6/2?": "TRUE",
    "Name": "Circle Skates Pintail - 1 credit(s)",
    "Purchases": 3,
    "Offset": -1,
    "Total": 2,
    "Revenue": 2,
    "": "",
    "__1": "",
    "Responses": "",
    "Threshold": ""
  },
  {
    "student_name": "Sami Sander",
    "product_name": "",
    "product_image": "",
    "product_url": "",
    "price": "",
    "Fully Done?": "FALSE",
    "Checked on 6/2?": "TRUE",
    "Name": "",
    "Purchases": "",
    "Offset": "",
    "Total": 0,
    "Revenue": 0,
    "": "",
    "__1": "",
    "Responses": "",
    "Threshold": ""
  },
  {
    "student_name": "Aravis Lenfest",
    "product_name": "Under the Sea Decks",
    "product_image": "https://storage.googleapis.com/skateboard_pictures/aravis.png",
    "product_url": "https://www.canva.com/design/DAFB6tJmxPU/hAhKwe4IunilEmWSje3VTw/view?website#1",
    "price": 2,
    "Fully Done?": "TRUE",
    "Checked on 6/2?": "TRUE",
    "Name": "Under the Sea Decks - 2 credit(s)",
    "Purchases": 1,
    "Offset": "",
    "Total": 1,
    "Revenue": 2,
    "": "",
    "__1": "",
    "Responses": "",
    "Threshold": ""
  },
  {
    "student_name": "Paty Margain-Junco",
    "product_name": "",
    "product_image": "",
    "product_url": "",
    "price": "",
    "Fully Done?": "FALSE",
    "Checked on 6/2?": "TRUE",
    "Name": "",
    "Purchases": "",
    "Offset": "",
    "Total": 0,
    "Revenue": 0,
    "": "",
    "__1": "",
    "Responses": "",
    "Threshold": ""
  },
  {
    "student_name": "Rhett Smerklo",
    "product_name": "",
    "product_image": "",
    "product_url": "",
    "price": "",
    "Fully Done?": "FALSE",
    "Checked on 6/2?": "TRUE",
    "Name": "",
    "Purchases": "",
    "Offset": "",
    "Total": 0,
    "Revenue": 0,
    "": "",
    "__1": "",
    "Responses": "",
    "Threshold": ""
  },
  {
    "student_name": "Weston Urhausen",
    "product_name": "Gudskull Classic Deck",
    "product_image": "https://storage.googleapis.com/skateboard_pictures/weston.png",
    "product_url": "https://www.canva.com/design/DAFB688U6Xk/fIaMccAJnGVxb8FqT9naDw/view?website#1",
    "price": 2,
    "Fully Done?": "TRUE",
    "Checked on 6/2?": "TRUE",
    "Name": "Gudskull Classic Deck - 2 credit(s)",
    "Purchases": 1,
    "Offset": "",
    "Total": 1,
    "Revenue": 2,
    "": "",
    "__1": "",
    "Responses": "",
    "Threshold": ""
  },
  {
    "student_name": "Halston Haselwood",
    "product_name": "killer koi deck",
    "product_image": "https://storage.googleapis.com/skateboard_pictures/Halston.png",
    "product_url": "https://www.canva.com/design/DAFCm1ZoN4U/QKtbIlZPrnUSMlJ-Y2y5bQ/view?website#1",
    "price": 3,
    "Fully Done?": "TRUE",
    "Checked on 6/2?": "TRUE",
    "Name": "killer koi deck - 3 credit(s)",
    "Purchases": "",
    "Offset": "",
    "Total": "",
    "Revenue": "",
    "": "",
    "__1": "",
    "Responses": "",
    "Threshold": ""
  }
]

import './App.css';
import ProductCard from "./components/ProductCard";
import {Box} from "@mui/material";
import styled from "@emotion/styled";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import {ReactComponent as SkateAlphaLogo} from './alpha.svg';
import {products} from './product_data';
import HowToModal from "./HowToModal";

const AppHeader = styled.div`
  background-color: #282c34;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-bottom: 30px;
`

function App() {
    const filteredProducts = products.filter(product => product.product_name !== "");
    const sortedProducts = filteredProducts.sort(product => product["Fully Done?"] === "TRUE" && product.product_image !== "https://account.ache.org/nfache1test/eWeb/images/DEMO1/notavailable.jpg" ? 1 : 0)
    return (
        <div>
            <AppHeader>
                <SkateAlphaLogo style={{height: "150px"}}/>

                <div style={{display: "flex", alignItems: "center"}}>
                    <div style={{display: "flex", alignItems: "center", paddingRight: 20}}>
                        <AttachMoneyIcon fontSize='large'/>
                        <p>3 credits</p>
                    </div>
                    <a style={{color: "inherit"}} href="https://forms.gle/9CQBUbanGYYpYBAT9" target="_blank">
                        <ShoppingCartIcon fontSize='large' style={{paddingRight: 20}}/>
                    </a>
                </div>
            </AppHeader>
            <div>
                <HowToModal/>
                <Box sx={{display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', rowGap: 5}}>
                    {sortedProducts.map(product => <ProductCard key={product.product_name} {...product}
                                                          setProductUrl={(url) => window.location = url}/>)}
                </Box>
            </div>
        </div>
    );
}

export default App;
